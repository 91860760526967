import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import type {
  BillingPlan,
  SubscriptionLimits,
  SubscriptionSliceType,
  SubscriptionStatus,
} from './subscriptionSliceTypes'
import {
  SUBSCRIPTION_FREE,
  trialAgencySubscriptionFactory,
  extendTrialSubscriptionFactory,
  SubscriptionType,
  SUBSCRIPTION_STARTER,
  SUBSCRIPTION_EARLY_BIRD,
  SUBSCRIPTION_PRO,
  SUBSCRIPTION_AGENCY,
} from './subscriptionConstants'
export * from './subscriptionSelectors'

export const initialState: SubscriptionSliceType = {
  status: 'idle',
  error: null,
  entity: null,
  limits: {
    hasExceededGlobalLimit: false,
    hasReachedGlobalLimit: false,
    exceeded: {
      projects: false,
      editors: false,
    },
    reached: {
      projects: false,
      editors: false,
    },
    maxPageHistoryDays: 0,
  },
  triggerLimitsCheck: null, // @author @michalpalenkar
} as SubscriptionSliceType

// ---------------
// Reducer
// ---------------

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: () => initialState,
  reducers: {
    updateSubscription: (
      state,
      action: PayloadAction<NonNullable<SubscriptionSliceType['entity']>>,
    ) => {
      state.status = 'succeeded'

      state.entity = {
        ...(state.entity || {}),
        ...action.payload,
      }
    },
    wsUpdateSubscription: (
      state,
      action: PayloadAction<NonNullable<SubscriptionSliceType['entity']>>,
    ) => {
      state.status = 'succeeded'
      state.entity = {
        ...(state.entity || {}),
        ...action.payload,
      }
    },
    activateTrial: (state) => {
      if (state.status !== 'succeeded') {
        return
      }
      state.entity = {
        ...(state.entity || {}),
        ...trialAgencySubscriptionFactory(),
      }
    },
    extendTrial: (state) => {
      if (state.status !== 'succeeded') {
        return
      }
      state.entity = {
        ...(state.entity || {}),
        ...extendTrialSubscriptionFactory({
          currentTrialExtensions: state.entity.currentTrialExtensions,
          trialEndAt: state.entity.trialEndAt,
        }),
      }
    },
    activateSubscription: (state, action: PayloadAction<BillingPlan>) => {
      if (state.status !== 'succeeded') {
        return
      }

      let subscrptionPreset = {}

      if (action.payload === 'free') {
        subscrptionPreset = SUBSCRIPTION_FREE
      }

      if (action.payload === 'starter') {
        subscrptionPreset = SUBSCRIPTION_STARTER
      }

      if (action.payload === 'earlyBird') {
        subscrptionPreset = SUBSCRIPTION_EARLY_BIRD
      }

      if (action.payload === 'proMonth') {
        subscrptionPreset = SUBSCRIPTION_PRO
      }

      if (action.payload === 'agencyMonth') {
        subscrptionPreset = SUBSCRIPTION_AGENCY
      }

      state.entity = {
        ...(state.entity || {}),
        ...subscrptionPreset,
      }
    },
    updateSubscriptionCurrentPlanStatus: (
      state,
      action: PayloadAction<SubscriptionStatus>,
    ) => {
      if (state.status !== 'succeeded') {
        return
      }
      state.entity.currentPlanStatus = action.payload
    },
    updateSubscriptionLimits: (
      state,
      action: PayloadAction<SubscriptionLimits>,
    ) => {
      state.limits = action.payload
    },
    resetSubscriptionLimits: (state) => {
      state.limits = initialState.limits
    },
    setTriggerLimitsCheck: (state) => {
      state.triggerLimitsCheck = new Date().getTime()
    },
  },
  extraReducers(builder) {
    builder.addCase('USER:LOGOUT', () => {
      return initialState
    })
  },
})

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useSubscriptionDispatch: () => AppDispatch = useDispatch
export const useSubscriptionSelector: TypedUseSelectorHook<RootState> =
  useSelector

export const {
  wsUpdateSubscription,
  updateSubscription,
  activateTrial,
  activateSubscription,
  updateSubscriptionCurrentPlanStatus,
  updateSubscriptionLimits,
  setTriggerLimitsCheck,
  extendTrial,
  resetSubscriptionLimits,
} = subscriptionSlice.actions

export default subscriptionSlice.reducer
