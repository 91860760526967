import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { AppDispatch, RootState } from './../../store'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { EditorSliceType } from './editorSliceTypes'
import {
  addBlock,
  assignReusableContentBlock,
  blurSelectedContentBlock,
  deleteBlock,
  deleteReusableContentBlock,
  updateBlock,
  updateContentBlocks,
  updateContentData,
  updateReusableContentBlock,
  updateReusableContentBlocks,
  wsUpdateContent,
} from '../content/contentSlice'
import { createGraphqlAsyncThunkByDocument } from '../../helpers/createGraphqlAsyncThunk'
import { BlockType } from '../content/contentSliceTypes'
import { asyncThunkFetcher } from '../../helpers/asyncThunkFetcher'
import { setSelectedModules } from '../whiteboard/whiteboardSlice'
import { projectFetchSuccess } from '../project/projectSlice'
export * from './editorSliceSelectors'
export * from './editorSliceMemoizedSelectorHooks'
export * from './editorSliceHookSelectors'
// ---------------
// Initial State
// ---------------
export const initialState: EditorSliceType = {
  mode: 'SELECT',
  prevMode: 'SELECT',
  zoom: 75,
  shouldZoomToFit: false,
  showComments: true,
  showContainerGuides: false,
  showResponsiveViews: true,
  showSitemap: false,
  showInviteUsersDropdown: false,
  blockPicker: {
    show: false,
  },
  selectPageModal: {
    show: false,
  },
  designerModal: {
    show: false,
  },
  pageSettingsForm: {
    show: false,
  },
  siteSettingsForm: {
    show: false,
  },
  dndDraggableData: null,
  collaborators: [],
  showLeftSidebar: false,
  showRightSidebar: false,
  showKeySheet: false,
  selectedEntity: {
    selectedBlockId: '',
    selectedComponentId: '',
    selectedFrame: 'default',
    triggeredBy: '',
    layout: true,
  },
  persistentComponentSelection: {},
  // TODO remove until content history WS implementation is done
  triggerVersionHistoryRefetch: null,
  editor: 'CONTENT',
  reusableContentBlocks: [],
  content: {
    contentId: '',
    pathId: '',
  },
  integrations: {},
  dynamization: {}, // saves the state of dynamization to the store, it is used for starting js plugins once all dynamization is complete
  undoEnabled: false,
  redoEnabled: false,
  canvasState: null,
  isBriefUpdatedByAi: false,
  isBriefGeneratingByAi: false,
  showPageHistory: false,
  workspaceId: null,
  myWorkspaceId: null,
} as EditorSliceType // https://github.com/reduxjs/redux-toolkit/pull/827

// ---------------
// Thunks
// ---------------

export const fetchReusableBlocks = createGraphqlAsyncThunkByDocument<
  'ReusableContentBlocksQueryDocument',
  'reusableContentBlocks'
>()('editor/fetchReusableBlocks', async ({ queryVariables }, thunkAPI) => {
  return await asyncThunkFetcher({
    query: 'ReusableContentBlocksQueryDocument',
    variables: queryVariables || {},
    selector: 'reusableContentBlocks',
    thunkAPI,
  })
})

export const renameReusableContentBlock = createGraphqlAsyncThunkByDocument<
  'RenameReusableContentBlockDocument',
  'updateNameReusableContentBlock'
>()(
  'content/reusableContentBlock/rename',
  async ({ queryVariables }, thunkAPI) => {
    return await asyncThunkFetcher({
      query: 'RenameReusableContentBlockDocument',
      selector: 'updateNameReusableContentBlock',
      variables: queryVariables,
      thunkAPI,
    })
  },
)

export const setModeOnce =
  (mode: EditorSliceType['mode']) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().editor.mode !== mode) {
      dispatch(setMode(mode))
    }
  }

// ---------------
// Reducer
// ---------------

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setMode: (state, action: PayloadAction<EditorSliceType['mode']>) => {
      const newMode = action.payload
      state.prevMode = state.mode

      if (newMode === 'COMMENT') {
        state.showComments = true
      }

      state.mode = newMode
    },
    setCanvasState: (
      state,
      action: PayloadAction<EditorSliceType['canvasState']>,
    ) => {
      state.canvasState = action.payload
    },
    setPrevMode: (state) => {
      const newMode = state.prevMode
      state.prevMode = state.mode

      if (newMode === 'COMMENT') {
        state.showComments = true
      }

      state.mode = newMode
    },
    setEditor: (state, action: PayloadAction<EditorSliceType['editor']>) => {
      const newEditorMode = action.payload

      if (newEditorMode === 'CONTENT') {
        state.showRightSidebar = false
      }

      state.editor = action.payload
    },
    setZoomTo: (state, action: PayloadAction<EditorSliceType['zoom']>) => {
      let newZoomState = action.payload

      if (newZoomState <= 5) {
        newZoomState = 5
      }

      if (newZoomState >= 200) {
        newZoomState = 200
      }

      state.zoom = newZoomState
      state.shouldZoomToFit = false
    },
    setTriggerVersionHistoryRefetch: (state, action: {}) => {
      state.triggerVersionHistoryRefetch = Date.now()
    },
    setDndDraggableData: (
      state,
      action: PayloadAction<EditorSliceType['dndDraggableData']>,
    ) => {
      state.dndDraggableData = action.payload
    },
    setZoomBy: (state, action: PayloadAction<number>) => {
      const by = action.payload
      let newZoomState = state.zoom + by

      if (newZoomState <= 5) {
        newZoomState = 5
      }

      if (newZoomState >= 200) {
        newZoomState = 200
      }

      state.zoom = newZoomState
    },
    setShouldZoomToFit: (
      state,
      action: PayloadAction<EditorSliceType['shouldZoomToFit']>,
    ) => {
      state.shouldZoomToFit = action.payload
    },
    setShowComments: (
      state,
      action: PayloadAction<EditorSliceType['showComments']>,
    ) => {
      const newShowCommentsState = action.payload

      if (newShowCommentsState === false && state.mode === 'COMMENT') {
        state.mode = 'SELECT'
      }

      state.showComments = action.payload
    },
    toggleShowComments: (state) => {
      const newShowCommentsState = !state.showComments

      if (newShowCommentsState === false && state.mode === 'COMMENT') {
        state.mode = 'SELECT'
      }

      state.showComments = !state.showComments
    },
    setShowResponsiveViews: (
      state,
      action: PayloadAction<EditorSliceType['showResponsiveViews']>,
    ) => {
      state.showResponsiveViews = action.payload
    },
    toggleShowResponsiveViews: (state) => {
      state.showResponsiveViews = !state.showResponsiveViews
    },
    setIsBriefGeneratingByAi: (
      state,
      action: PayloadAction<EditorSliceType['isBriefGeneratingByAi']>,
    ) => {
      state.isBriefGeneratingByAi = action.payload
    },
    toggleShowSitemap: (state) => {
      state.showSitemap = !state.showSitemap
    },
    setShowSitemap: (
      state,
      action: PayloadAction<EditorSliceType['showSitemap']>,
    ) => {
      state.showSitemap = action.payload
    },
    setBlockPicker: (
      state,
      action: PayloadAction<EditorSliceType['blockPicker']>,
    ) => {
      const { show, data } = action.payload
      state.blockPicker = {
        show,
        data,
      }
    },
    toggleShowBlockPicker: (state) => {
      state.blockPicker = {
        ...state.blockPicker,
        show: !state.blockPicker.show,
      }
    },
    toggleShowContainerGuides: (state) => {
      state.showContainerGuides = !state.showContainerGuides
    },
    setDesignerModal: (
      state,
      action: PayloadAction<EditorSliceType['designerModal']>,
    ) => {
      state.designerModal = action.payload
    },
    setPageSettingsForm: (
      state,
      action: PayloadAction<EditorSliceType['pageSettingsForm']>,
    ) => {
      state.pageSettingsForm = action.payload
    },
    setSiteSettingsForm: (
      state,
      action: PayloadAction<EditorSliceType['siteSettingsForm']>,
    ) => {
      state.siteSettingsForm = action.payload
    },
    wsSetCollaborators: (
      state,
      action: PayloadAction<EditorSliceType['collaborators']>,
    ) => {
      state.collaborators = action.payload
    },
    setShowLeftSidebar: (
      state,
      action: PayloadAction<EditorSliceType['showLeftSidebar']>,
    ) => {
      state.showLeftSidebar = action.payload
    },
    toggleShowLeftSidebar: (state) => {
      state.showLeftSidebar = !state.showLeftSidebar
    },
    setShowRightSidebar: (
      state,
      action: PayloadAction<EditorSliceType['showRightSidebar']>,
    ) => {
      state.showRightSidebar = action.payload
    },
    toggleShowRightSidebar: (state) => {
      state.showRightSidebar = !state.showRightSidebar
    },
    toggleShowKeySheet: (state) => {
      state.showKeySheet = !state.showKeySheet
    },
    setShowInviteUsersDropdown: (
      state,
      action: PayloadAction<EditorSliceType['showInviteUsersDropdown']>,
    ) => {
      state.showInviteUsersDropdown = action.payload
    },
    toggleShowInviteUsersDropdown: (state) => {
      state.showInviteUsersDropdown = !state.showInviteUsersDropdown
    },
    setPersistentComponentSelection: (
      state,
      action: PayloadAction<{
        blockId: string
        selectedComponentId: string
        uniqueSelector: string
      }>,
    ) => {
      const { blockId, selectedComponentId, uniqueSelector } = action.payload

      state.persistentComponentSelection = {
        ...state.persistentComponentSelection,
        [blockId]: {
          ...state.persistentComponentSelection[blockId],
          [uniqueSelector]: selectedComponentId,
        },
      }
    },
    setSelectedEntity: (
      state,
      action: PayloadAction<EditorSliceType['selectedEntity']>,
    ) => {
      const {
        selectedBlockId,
        selectedComponentId,
        selectedFrame,
        triggeredBy,
        focusedFormMolecule,
      } = action.payload

      const isLayoutSelected = !selectedBlockId && !selectedComponentId

      let resolvedSelectedFrame = state.selectedEntity.selectedFrame

      if (selectedFrame) {
        resolvedSelectedFrame = selectedFrame
      }
      if (state.showPageHistory) {
        state.showPageHistory = false
      }

      state.selectedEntity = {
        selectedBlockId,
        selectedComponentId,
        layout: isLayoutSelected,
        selectedFrame: resolvedSelectedFrame,
        triggeredBy,
        focusedFormMolecule,
      }
    },
    setContent: (state, action: PayloadAction<EditorSliceType['content']>) => {
      state.content = action.payload
    },
    setIntegrations: (
      state,
      action: PayloadAction<EditorSliceType['integrations']>,
    ) => {
      state.integrations = action.payload
    },
    resetEditorState: (
      state,
      action: PayloadAction<{
        ignoreFields?: Array<keyof EditorSliceType>
      }>,
    ) => {
      const ignoreFields = action?.payload?.ignoreFields || []
      const newState = { ...initialState }

      if (ignoreFields.includes('showSitemap')) {
        newState.showSitemap = state.showSitemap
        newState.showLeftSidebar = true
        newState.showRightSidebar = true
      }

      if (ignoreFields.includes('showPageHistory')) {
        newState.showPageHistory = state.showPageHistory
      }

      if (ignoreFields.includes('workspaceId')) {
        newState.workspaceId = state.workspaceId
      }

      if (ignoreFields.includes('myWorkspaceId')) {
        newState.myWorkspaceId = state.myWorkspaceId
      }

      return newState
    },
    setBlockDynamization: (
      state,
      action: PayloadAction<{ blockIds: BlockType['id'][]; state: boolean }>,
    ) => {
      action.payload.blockIds.forEach((id) => {
        if (state.dynamization[id] !== undefined) {
          state.dynamization[id] = action.payload.state
        }
      })
    },
    deleteBlockDynamization: (state, action: PayloadAction<BlockType[]>) => {
      action.payload.forEach((block) => {
        if (state.dynamization[block.id]) {
          delete state.dynamization[block.id]
        }
      })
    },
    addBlockDynamization: (state, action: PayloadAction<BlockType[]>) => {
      action.payload.forEach((block) => {
        state.dynamization[block.id] = false
      })
    },
    setWorkspaceId: (state, action: PayloadAction<string | null>) => {
      state.workspaceId = action.payload
    },
    setMyWorkspaceId: (state, action: PayloadAction<string | null>) => {
      state.myWorkspaceId = action.payload
    },
    setReusableContentBlockIsolatedMode: (
      state,
      action: PayloadAction<{
        blockId?: string
        contentBlocks?: BlockType[]
      }>,
    ) => {
      const contentBlocks = action.payload.contentBlocks

      if (action.payload.blockId) {
        state.dynamization = {
          [action.payload.blockId]: false,
        }
      } else {
        if (contentBlocks) {
          state.dynamization = contentBlocks.reduce<{
            [key: string]: boolean
          }>(
            (acc, contentBlock) => ({
              ...acc,
              [contentBlock.id]: false,
            }),
            {},
          )
        }
      }
    },
    updateUndoEnabled: (state, action: PayloadAction<boolean>) => {
      state.undoEnabled = action.payload
    },
    updateRedoEnabled: (state, action: PayloadAction<boolean>) => {
      state.redoEnabled = action.payload
    },
    toggleUi: (state) => {
      const uiVisiblityState = state.showLeftSidebar && state.showRightSidebar
      state.showLeftSidebar = !uiVisiblityState
      state.showRightSidebar = !uiVisiblityState
    },
    setShowPageHistory: (
      state,
      action: PayloadAction<EditorSliceType['showPageHistory']>,
    ) => {
      state.showPageHistory = action.payload
      state.showLeftSidebar = true
      state.showRightSidebar = true
    },
    togglePageHistory: (state) => {
      state.showPageHistory = !state.showPageHistory

      if (state.showRightSidebar === false) {
        state.showRightSidebar = true
        state.showPageHistory = true
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase('USER:LOGOUT', () => {
        return initialState
      })
      .addCase(setSelectedModules, (state, action) => {
        if (state.showSitemap && action.payload.length > 0) {
          state.showSitemap = false
        }
      })
      .addCase(renameReusableContentBlock.fulfilled, (state, action) => {
        const { issuedReusableContentBlock } = action.payload

        const blockIndex = state.reusableContentBlocks.findIndex(
          (reusableBlock) =>
            reusableBlock.id === issuedReusableContentBlock!.id,
        )

        state.reusableContentBlocks[blockIndex].name =
          issuedReusableContentBlock!.name
      })
      .addCase(assignReusableContentBlock.fulfilled, (state, action) => {
        const { id } = action.payload

        state.dynamization = {
          ...state.dynamization,
          [id]: false,
        }
      })
      .addCase(deleteReusableContentBlock.fulfilled, (state, action) => {
        const { issuedReusableContentBlock } = action.payload

        state.reusableContentBlocks = state.reusableContentBlocks.filter(
          (block) => block.id !== issuedReusableContentBlock!.id,
        )
      })
      .addCase(fetchReusableBlocks.fulfilled, (state, action) => {
        const reusableContentBlocks = action.payload.filter(
          (reusableContentBlock) => reusableContentBlock.status === 'ISSUED',
        )
        state.reusableContentBlocks = reusableContentBlocks
      })
      .addCase(deleteBlock.fulfilled, (state, action) => {
        const { id } = action.meta.arg.queryVariables
        if (state.dynamization[id]) {
          delete state.dynamization[id]
        }
      })
      .addCase(addBlock.fulfilled, (state, action) => {
        const { id } = action.payload
        state.dynamization[id] = false
      })
      .addCase(updateReusableContentBlocks.fulfilled, (state, action) => {
        const updatedReusableContentBlocks = action.payload || []

        updatedReusableContentBlocks.forEach(
          ({ draftReusableContentBlock }) => {
            if (draftReusableContentBlock) {
              const { parentId } = draftReusableContentBlock
              if (parentId) {
                state.dynamization[parentId] = false
              }
            }
          },
        )
      })
      .addCase(updateReusableContentBlock.fulfilled, (state, action) => {
        const { draftReusableContentBlock } = action.payload

        if (draftReusableContentBlock) {
          const { parentId } = draftReusableContentBlock
          state.dynamization[parentId] = false
        }
      })
      .addCase(updateBlock.fulfilled, (state, action) => {
        const blockId = action.payload.id
        // we must set it on pending status otherwise when res returns from the server
        // the block has already been dynamized and in most cases the returned data has no config change
        // thus no dynamization (useEffect dependency doesnt change) takes place and the dynamzation state would not be set to true

        // TODO there will be an issue here if the server responds with materialised data but the dynamization will takes place during
        // once pending state has been dynamized the new config will be dynamized again but plugins will initialize again
        state.dynamization[blockId] = false
      })
      .addCase(updateContentBlocks.fulfilled, (state, action) => {
        const updatedBlocks = action.payload || []

        updatedBlocks.forEach(({ id }) => {
          state.dynamization[id] = false
        })
      })
      .addCase(blurSelectedContentBlock.fulfilled, (state) => {
        state.selectedEntity = initialState.selectedEntity
      })
      .addCase(updateContentData.pending, (state, action) => {
        state.isBriefUpdatedByAi =
          action?.meta.arg.queryVariables.isBriefUpdatedByAi || false
      })
      .addCase(wsUpdateContent, (state, action) => {
        if (action.payload.preferences.AIPrompt) {
          state.isBriefUpdatedByAi = false
        }
      })
  },
})

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useEditorDispatch: () => AppDispatch = useDispatch
export const useEditorSelector: TypedUseSelectorHook<RootState> = useSelector

// ---------------
// SELECTORS
// ---------------

export const {
  setMode,
  setCanvasState,
  setPrevMode,
  setEditor,
  setZoomTo,
  setWorkspaceId,
  setMyWorkspaceId,
  setTriggerVersionHistoryRefetch,
  setDndDraggableData,
  setZoomBy,
  setShowComments,
  toggleShowComments,
  setShowResponsiveViews,
  toggleShowResponsiveViews,
  setBlockPicker,
  toggleShowBlockPicker,
  wsSetCollaborators,
  setShouldZoomToFit,
  setShowLeftSidebar,
  toggleShowLeftSidebar,
  setShowRightSidebar,
  toggleShowRightSidebar,
  setSelectedEntity,
  setPersistentComponentSelection,
  setContent,
  setReusableContentBlockIsolatedMode,
  setIntegrations,
  resetEditorState,
  setBlockDynamization,
  deleteBlockDynamization,
  addBlockDynamization,
  setDesignerModal,
  setPageSettingsForm,
  setSiteSettingsForm,
  updateUndoEnabled,
  updateRedoEnabled,
  toggleUi,
  toggleShowKeySheet,
  toggleShowContainerGuides,
  toggleShowSitemap,
  setShowSitemap,
  setIsBriefGeneratingByAi,
  setShowInviteUsersDropdown,
  toggleShowInviteUsersDropdown,
  setShowPageHistory,
  togglePageHistory,
} = editorSlice.actions

export default editorSlice.reducer
