import type { BillingSubscription } from './subscriptionSliceTypes'

export type SubscriptionType = Partial<BillingSubscription>

export const TRIAL_AGENCY_SUBSCRIPTION: SubscriptionType = {
  maxEditors: 100,
  maxProjects: 100,
  currentPlanName: 'agencyMonth',
  currentPlanStatus: 'trialing',
  isTrialActive: true,
  currentTrialExtensions: 1,
}

export const EXTEND_TRIAL_SUBSCRIPTIION: SubscriptionType = {
  ...TRIAL_AGENCY_SUBSCRIPTION,
}

export function trialAgencySubscriptionFactory(): SubscriptionType {
  return {
    ...TRIAL_AGENCY_SUBSCRIPTION,
    trialStartAt: new Date().toISOString(),
    trialEndAt: new Date(
      new Date().getTime() + 7 * 24 * 60 * 60 * 1000,
    ).toISOString(),
  }
}

export function extendTrialSubscriptionFactory({
  currentTrialExtensions,
  trialEndAt,
}: {
  currentTrialExtensions: number
  trialEndAt?: string
}): SubscriptionType {
  const currentTrialEnd = trialEndAt ? new Date(trialEndAt) : new Date()
  return {
    ...EXTEND_TRIAL_SUBSCRIPTIION,
    currentTrialExtensions: currentTrialExtensions + 1,
    trialStartAt: new Date().toISOString(),
    trialEndAt: new Date(
      currentTrialEnd.getTime() + 7 * 24 * 60 * 60 * 1000,
    ).toISOString(),
  }
}

export const SUBSCRIPTION_FREE: SubscriptionType = {
  maxEditors: 0,
  maxProjects: 1,
  currentPlanName: 'free',
  currentPlanStatus: 'active',
}

export const SUBSCRIPTION_STARTER: SubscriptionType = {
  maxEditors: 0,
  maxProjects: 1,
  currentPlanName: 'starter',
  currentPlanStatus: 'active',
}

export const SUBSCRIPTION_PRO: SubscriptionType = {
  maxEditors: 100,
  maxProjects: 100,
  currentPlanName: 'proMonth',
  currentPlanStatus: 'active',
}

export const SUBSCRIPTION_EARLY_BIRD: SubscriptionType = {
  maxEditors: 100,
  maxProjects: 100,
  currentPlanName: 'earlyBird',
  currentPlanStatus: 'active',
}

export const SUBSCRIPTION_AGENCY: SubscriptionType = {
  maxEditors: 100,
  maxProjects: 100,
  currentPlanName: 'agencyMonth',
  currentPlanStatus: 'active',
}
